<template>
  <b-card
    bg-variant="light"
    header="勤智生态"
    header-tag="h2"
    header-class="HomeNews-header mb-3 mb-xl-4 p-0 border-bottom-0 bg-transparent"
    body-class="position-relative p-0"
    class="h-100 px-3 py-4 p-lg-5 px-xl-7 border-0 rounded-0"
  >
    <ul class="HomeNews-list list-unstyled">
      <b-media
        v-for="(el, index) in list.slice(0, 4)"
        :key="index"
        tag="li"
        to="/ablout"
        class="mb-3 mb-xl-5"
      >
        <template #aside>
          <a
            :href="el.url"
            target="_blank"
            :style="{ backgroundImage: `url('${el.thumb}')` }"
          />
        </template>
        <h6>
          <a :href="el.url" target="_blank">{{ el.title }}</a>
        </h6>
        <p class="mb-0">{{ el.updatetime }}</p>
      </b-media>
    </ul>
    <home-more to="/news" class="HomeNews-more" />
  </b-card>
</template>

<script>
import HomeMore from "./HomeMore";

export default {
  name: "HomeNews",
  components: { HomeMore },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.HomeNews {
  &-header {
    font-size: $h4-font-size;
  }

  &-more {
    top: -38px;
  }

  &-list {
    .media-aside {
      width: 100px;
      height: 100px;
      overflow: hidden;

      a {
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    h6 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      line-height: 1.5;
    }

    p {
      color: $secondary;
    }
  }

  @include media-breakpoint-up(sm) {
    &-list {
      .media-aside {
        width: 200px;
        height: 113px;
      }

      h6 {
        font-size: $h5-font-size;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-header {
      font-size: $h3-font-size;
    }

    &-list {
      .media-aside {
        width: 113px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &-header {
      font-size: $h1-font-size;
    }

    &-more {
      top: -48px;
    }

    &-list {
      .media-aside {
        width: 200px;
        height: 113px;
      }
    }
  }
}
</style>
