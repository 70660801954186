<template>
  <swiper ref="mySwiper" :options="options">
    <swiper-slide v-for="(el, i) in list" :key="i">
      <slot v-bind:el="el" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "BaseSwiper",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { swiper, swiperSlide },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    slideNext(speed, runCallbacks) {
      this.swiper.slideNext(speed, runCallbacks);
    },
    slidePrev(speed, runCallbacks) {
      this.swiper.slidePrev(speed, runCallbacks);
    },
  },
};
</script>
