<template>
  <b-navbar toggleable="lg" class="c-nav px-3 px-lg-5 px-xl-7 text-center">
    <b-navbar-brand to="/">
      <img src="@/assets/logo.png" class="c-nav-logo" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-navbar-nav class="navbar-nav">
          <b-nav-item
            v-for="(el, i) in navData"
            :key="i"
            :to="el.to"
            :active="el.key === parent"
          >
            {{ el.name }}
          </b-nav-item>
          <b-nav-text
            class="
              c-nav-lang
              d-inline-flex
              justify-content-center
              align-items-center
              ml-lg-3 ml-xl-4
            "
          >
            <a class="p-0 mr-1 text-decoration-none">中</a>
            /
            <a href="//en.triwise.cn" class="p-0 ml-1 text-decoration-none">EN</a>
          </b-nav-text>
        </b-navbar-nav>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNav",
  data() {
    return { parent: "" };
  },
  computed: {
    ...mapGetters(["navData"]),
  },
  watch: {
    $route(val) {
      const { meta } = val;
      this.parent = meta.parent;
    },
  },
  mounted() {
    const { meta } = this.$route;
    this.parent = meta.parent;
  },
};
</script>

<style lang="scss" scoped>
.c-nav {
  border-bottom: 8px solid $primary;

  &-logo {
    height: 80px;
  }

  &-lang {
    font-size: $font-size-sm;
    color: $navbar-light-color;

    a {
      font-size: inherit;
      color: $navbar-light-color;

      &:hover {
        color: $black;
      }

      &[href] {
        font-size: $font-size-base;
        color: $navbar-light-hover-color;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .navbar-nav {
      font-size: $font-size-lg;
    }
  }
}
</style>
