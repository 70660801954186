<template>
  <div class="c-video position-relative">
    <div
      v-if="img"
      class="c-video--poster"
      :style="{
        backgroundImage: `url(${img})`,
      }"
      @click="$emit('click')"
    />
    <iframe
      v-if="url"
      :src="url"
      frameborder="0"
      allowfullscreen=""
      class="c-video--player"
    />
    <b-img
      v-if="!url && !img"
      src="@/assets/icon-play.png"
      class="c-video--icon position-absolute"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  name: "BaseVideo",
  props: {
    img: { type: String, default: "" },
    url: { type: String, default: "" },
  },
};
</script>

<style lang="scss">
.c-video {
  overflow: hidden;
  background-color: $black;
  color: $white;

  &--poster {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--player {
    width: 100%;
    height: 100%;
  }

  &--icon {
    top: 50%;
    left: 50%;
    width: auto;
    height: 40%;
    max-width: 100px;
    max-height: 100px;
    transform: translate(-50%, -50%);
    opacity: 0.8;
  }
}
</style>
