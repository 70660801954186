<template>
  <div id="app">
    <the-nav />
    <router-view />
    <the-footer />
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";
import TheFooter from "@/components/TheFooter.vue";

export default {
  components: { TheNav, TheFooter },
};
</script>
