<template>
  <b-container fluid class="px-0">
    <b-row
      no-gutters
      :class="[
        'px-3',
        'px-lg-5',
        'px-xl-7',
        'py-4',
        'py-lg-5',
        'py-xl-6',
        !isHome && 'bg-light',
      ]"
    >
      <b-col cols="12" sm="4" md="6" xl="2">
        <b-img
          width="120"
          height="120"
          src="http://user-assets.sxlcdn.com/images/200163/FkdbW7BStR5wKvPWGRxOD0s9Vtpv.png?imageMogr2/strip/auto-orient/thumbnail/300x300%3E/format/png"
          alt=""
        />
      </b-col>
      <b-col
        v-for="(el, i) in list"
        :key="i"
        :cols="el.cols"
        :sm="el.sm"
        :md="el.md"
        :xl="el.xl"
      >
        <b-card
          :img-src="require(`@/assets/${el.icon}`)"
          img-alt=""
          img-width="84"
          img-height="84"
          img-left
          class="c-footer-card border-0"
        >
          <b-card-text>
            <h6 class="font-weight-bold text-black-50">{{ el.title }}：</h6>
            <p
              v-for="(desc, descIndex) in el.content"
              :key="descIndex"
              :class="`m-0 text-secondary${el.class ? ` ${el.class}` : ''}`"
            >
              {{ desc }}
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      cols="1"
      cols-lg="2"
      :class="[
        'c-footer-copyright',
        'justify-content-md-center',
        'mx-3',
        'mx-lg-5',
        'mx-xl-7',
        'pt-3',
        'pt-lg-4',
        'pb-4',
        'pb-lg-5',
        'pb-xl-6',
        'text-black-50',
        isHome && 'border-top',
      ]"
    >
      <b-col lg="auto">
        <p class="text-center mb-0">
          © 2020 深圳前海勤智国际资本管理有限公司 版权所有
        </p>
      </b-col>
      <b-col lg="auto">
        <p class="text-center mb-0">粤ICP备17058923号-1</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      list: [
        {
          title: "联系邮箱",
          icon: "icon-email.png",
          content: ["bp@triwise.cn", "market@triwise.net"],
          cols: 12,
          sm: 6,
          md: 6,
          xl: 3,
        },
        {
          title: "联系电话",
          icon: "icon-tel.png",
          content: ["86-0755-86912699"],
          class: "font-weight-bold font-italic text-info",
          cols: 12,
          sm: 12,
          md: 6,
          xl: 4,
        },
        {
          title: "公司地址",
          icon: "icon-addr.png",
          content: [
            "广东省深圳市南山区",
            "高新南七道国家高技术产业创新中心 A15",
            "上海市浦东新区",
            "银城中路8号海银金融中心42层4210室",
          ],
          cols: 12,
          sm: 12,
          md: 6,
          xl: 3,
        },
      ],
    };
  },
  computed: {
    isHome() {
      return this.$route.name === "Home";
    },
  },
};
</script>

<style lang="scss" scoped>
.c-footer {
  &-card {
    height: 120px;
    background-color: transparent;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
    }

    .card-body {
      display: flex;
      align-items: center;
    }

    .text-info {
      font-size: $h4-font-size;
    }
  }

  &-copyright {
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(lg) {
    &-card {
      h6 {
        font-size: $h5-font-size;
      }

      .text-info {
        font-size: $h2-font-size;
      }
    }
  }
}
</style>
