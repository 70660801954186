import axios from "axios";
import qs from "qs";

/**
 * request
 * @param {object} options
 * @param {Boolean} options.url 请求地址
 * @param {string} options.method 请求方式 默认 get
 * @param {object} options.data 请求传参
 *
 */
export default function (options = {}) {
  const { url, method, data = {} } = options;

  return new Promise((resolve, reject) => {
    // 创建 axios 实例
    const instance = axios.create({
      baseURL:
        process.env.NODE_ENV === "production"
          ? "//triwiseadmin.triwise.cn"
          : "/triwise",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    const error = (res) => {
      const url_split = url.split("/");
      const api = url_split[url_split.length - 1].toLocaleLowerCase();
      // 错误码
      res.response &&
        console.error(`服务器错误[${api}_${res.response.status}]`);

      return Promise.reject(res);
    };

    // request interceptor
    instance.interceptors.request.use((config) => {
      // 处理传参
      if (config.method === "post") {
        config.data = qs.stringify(data);
      } else {
        config.params = data;
      }

      return config;
    }, error);

    // response interceptor
    instance.interceptors.response.use((response) => {
      return response.data;
    }, error);

    instance({ url, method })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
