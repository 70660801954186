var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('b-row',{class:[
      'px-3',
      'px-lg-5',
      'px-xl-7',
      'py-4',
      'py-lg-5',
      'py-xl-6',
      !_vm.isHome && 'bg-light' ],attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12","sm":"4","md":"6","xl":"2"}},[_c('b-img',{attrs:{"width":"120","height":"120","src":"http://user-assets.sxlcdn.com/images/200163/FkdbW7BStR5wKvPWGRxOD0s9Vtpv.png?imageMogr2/strip/auto-orient/thumbnail/300x300%3E/format/png","alt":""}})],1),_vm._l((_vm.list),function(el,i){return _c('b-col',{key:i,attrs:{"cols":el.cols,"sm":el.sm,"md":el.md,"xl":el.xl}},[_c('b-card',{staticClass:"c-footer-card border-0",attrs:{"img-src":require(("@/assets/" + (el.icon))),"img-alt":"","img-width":"84","img-height":"84","img-left":""}},[_c('b-card-text',[_c('h6',{staticClass:"font-weight-bold text-black-50"},[_vm._v(_vm._s(el.title)+"：")]),_vm._l((el.content),function(desc,descIndex){return _c('p',{key:descIndex,class:("m-0 text-secondary" + (el.class ? (" " + (el.class)) : ''))},[_vm._v(" "+_vm._s(desc)+" ")])})],2)],1)],1)})],2),_c('b-row',{class:[
      'c-footer-copyright',
      'justify-content-md-center',
      'mx-3',
      'mx-lg-5',
      'mx-xl-7',
      'pt-3',
      'pt-lg-4',
      'pb-4',
      'pb-lg-5',
      'pb-xl-6',
      'text-black-50',
      _vm.isHome && 'border-top' ],attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',{attrs:{"lg":"auto"}},[_c('p',{staticClass:"text-center mb-0"},[_vm._v(" © 2020 深圳前海勤智国际资本管理有限公司 版权所有 ")])]),_c('b-col',{attrs:{"lg":"auto"}},[_c('p',{staticClass:"text-center mb-0"},[_vm._v("粤ICP备17058923号-1")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }