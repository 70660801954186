<template>
  <b-container fluid class="px-0">
    <home-about :content="aboutContent" :video="aboutVideo" />
    <home-case :list="caseList" />
    <b-row cols="1" cols-lg="2" no-gutters>
      <b-col><home-story :content="storyContent" /></b-col>
      <b-col><home-news :list="newsList" /></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { fatchCurrent } from "@/api";
import HomeAbout from "./components/HomeAbout";
import HomeCase from "./components/HomeCase";
import HomeStory from "./components/HomeStory";
import HomeNews from "./components/HomeNews";

export default {
  name: "Home",
  components: { HomeAbout, HomeCase, HomeStory, HomeNews },
  data() {
    return {
      aboutContent: "",
      caseList: [],
      newsList: [],
      storyContent: {},
      aboutVideo: "",
    };
  },
  computed: {
    ...mapGetters(["navData"]),
  },
  mounted() {
    // 更新标题
    const menu = this.navData.find((el) => el.to === this.$route.path);
    const title = menu ? menu.name : "";
    if (title) document.title = `${title.replace(/\s*/g, "")} - 勤智资本`;

    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(20);
      const { content, qinzhishengtai, touzianli, qiyewenhua, video_url } =
        data || {};
      if (content) this.aboutContent = content;
      if (touzianli) this.caseList = touzianli;
      if (qinzhishengtai) this.newsList = qinzhishengtai;
      if (qiyewenhua) this.storyContent = qiyewenhua;
      if (video_url) this.aboutVideo = video_url;
    },
  },
};
</script>
