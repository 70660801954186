<template>
  <b-button
    :to="to"
    variant="link-light"
    size="sm"
    :class="[
      'HomeMore',
      mode && `HomeMore-${mode}`,
      'position-absolute',
      'd-inline-flex',
      'align-items-center',
      'p-0',
    ]"
  >
    更多
    <b-icon icon="chevron-right" scale="0.8" style="margin-top: 2px" />
  </b-button>
</template>

<script>
export default {
  name: "HomeMore",
  props: {
    to: { type: String, default: "" },
    mode: { type: String, default: "" }, // options: light | dark
  },
};
</script>

<style lang="scss" scoped>
.HomeMore {
  right: 0;

  &.btn {
    color: $gray-600;
  }

  &-light.btn {
    color: $white;

    &:hover {
      color: $gray-300;
    }
  }

  &-dark.btn {
    color: $gray-800;

    &:hover {
      color: $black;
    }
  }

  @include media-breakpoint-up(xl) {
    &.btn {
      font-size: $font-size-base;
    }
  }
}
</style>
