export default [
  {
    path: "/philosophy",
    name: "Philosophy",
    component: () =>
      import(
        /* webpackChunkName: "philosophy" */ "@/views/Investment/Philosophy"
      ),
  },
  {
    path: "/direction",
    name: "Direction",
    component: () =>
      import(
        /* webpackChunkName: "direction" */ "@/views/Investment/Direction"
      ),
  },
  {
    path: "/standard",
    name: "Standard",
    component: () =>
      import(/* webpackChunkName: "standard" */ "@/views/Investment/Standard"),
  },
  {
    path: "/risk",
    name: "Risk",
    component: () =>
      import(/* webpackChunkName: "risk" */ "@/views/Investment/Risk"),
  },
];
