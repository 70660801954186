export default [
  {
    path: "/watch",
    name: "Watch",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/News/Watch"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import(/* webpackChunkName: "news" */ "@/views/News/News"),
  },
  {
    path: "/study",
    name: "Study",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/News/Study"),
  },
];
