export default [
  {
    path: "/intro",
    name: "Intro",
    component: () =>
      import(/* webpackChunkName: "intro" */ "@/views/About/Intro"),
  },
  {
    path: "/prize",
    name: "Prize",
    component: () =>
      import(/* webpackChunkName: "prize" */ "@/views/About/Prize"),
  },
  {
    path: "/culture",
    name: "Culture",
    component: () =>
      import(/* webpackChunkName: "culture" */ "@/views/About/Culture"),
  },
];
