import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { navData: {} },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    navData: () => {
      return [
        {
          key: "index",
          name: "首 页",
          to: "/",
        },
        {
          key: "about",
          name: "关于我们",
          to: "/intro",
          children: [
            { name: "勤智简介", to: "/intro" },
            { name: "公司荣誉", to: "/prize" },
            { name: "企业文化", to: "/culture" },
          ],
        },
        {
          key: "investment",
          name: "投资业务",
          to: "/philosophy",
          children: [
            { name: "投资理念", to: "/philosophy" },
            { name: "投资方向", to: "/direction" },
            { name: "投资标准", to: "/standard" },
            { name: "风控体系", to: "/risk" },
          ],
        },
        {
          key: "portfolio",
          name: "投资案例",
          to: "/portfolio",
        },
        {
          key: "team",
          name: "管理团队",
          to: "/core",
        },
        // {
        //   key: "team",
        //   name: "管理团队",
        //   to: "/core",
        //   children: [
        //     { name: "核心团队", to: "/core" },
        //     { name: "专家团队", to: "/consultant" },
        //   ],
        // },
        {
          key: "news",
          name: "新闻资讯",
          to: "/news",
          children: [
            { name: "公司新闻", to: "/news" },
            { name: "专题研究", to: "/study" },
            { name: "行业动态", to: "/watch" },
          ],
        },
        {
          key: "contact",
          name: "联系我们",
          to: "/contact",
        },
      ];
    },
  },
});
