<template>
  <b-row no-gutters class="HomeAbout position-relative overflow-hidden">
    <b-col
      cols="12"
      class="HomeAbout-banner"
      :style="{
        backgroundImage: `url(${require('@/assets/banner-index-text-s.jpg')})`,
      }"
    />
    <b-col cols="12">
      <b-card
        header="关于勤智"
        header-tag="h2"
        header-class="HomeAbout-card--header position-relative mb-3 mb-xl-4 px-0 pt-0 bg-transparent"
        body-class="HomeAbout-card--body position-relative p-0"
        class="HomeAbout-card mr-lg-5 mr-xl-7 px-3 py-4 px-lg-5 px-xl-6 py-xl-5 float-lg-right border-0 rounded-0"
      >
        <b-card-text class="mb-0">
          <div v-html="content" class="HomeAbout-card--text" />
        </b-card-text>
        <home-more to="/intro" class="HomeAbout-more" />
        <base-video
          :img="require('@/assets/img-video.jpg')"
          class="HomeAbout-video"
          @click="onclick()"
        />
      </b-card>
    </b-col>
    <b-modal id="j-video" size="xl" centered hide-footer>
      <base-video :url="video" class="HomeAbout-video-player" />
    </b-modal>
  </b-row>
</template>

<script>
import BaseVideo from "@/components/BaseVideo";
import HomeMore from "./HomeMore";

export default {
  name: "HomeAbout",
  props: {
    content: { type: String, default: "" },
    video: { type: String, default: "" },
  },
  components: { BaseVideo, HomeMore },
  methods: {
    onclick() {
      this.$bvModal.show("j-video");
    },
  },
};
</script>

<style lang="scss" scoped>
.HomeAbout {
  &-banner {
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-card {
    &--header {
      font-size: $h4-font-size;
    }
  }

  &-more {
    top: -49px;
  }

  &-video {
    height: 60px;
    cursor: pointer;

    &-player {
      height: 250px;
    }
  }

  @include media-breakpoint-up(md) {
    &-banner {
      height: 200px;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 450px;

    &-banner {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
    }

    &-card {
      width: 450px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);

      &--header {
        font-size: $h3-font-size;
      }
    }

    &-video {
      &-player {
        height: 450px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    height: 600px;

    &-card {
      width: 650px;

      &--header {
        font-size: $h1-font-size;
      }

      &--body {
        font-size: $font-size-lg;
        line-height: 2;
      }
    }

    &-more {
      top: -59px;
    }

    &-video {
      height: 70px;

      &-player {
        height: 650px;
      }
    }
  }
}
</style>
