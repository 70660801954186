import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import about from "./about";
import investment from "./investment";
import team from "./team";
import portfolio from "./portfolio";
import news from "./news";
import contact from "./contact";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { parent: "index" },
  },
  ...about.map((el) => {
    return { ...el, meta: { parent: "about" } };
  }),
  ...investment.map((el) => {
    return { ...el, meta: { parent: "investment" } };
  }),
  ...team.map((el) => {
    return { ...el, meta: { parent: "team" } };
  }),
  ...portfolio.map((el) => {
    return { ...el, meta: { parent: "portfolio" } };
  }),
  ...news.map((el) => {
    return { ...el, meta: { parent: "news" } };
  }),
  ...contact.map((el) => {
    return { ...el, meta: { parent: "contact" } };
  }),
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
