<template>
  <b-card
    header="企业文化"
    header-tag="h2"
    header-class="HomeStory-header mb-3 mb-xl-4 p-0 border-bottom-0 bg-transparent"
    body-class="HomeStory-body position-relative p-0"
    class="HomeStory h-100 px-3 py-4 p-lg-5 px-xl-7 border-0 rounded-0"
  >
    <base-video :url="content.video_url" class="HomeStory-video mb-3 mb-xl-4" />
    <b-card-text v-if="content.description" class="mb-0">
      {{ content.description }}
    </b-card-text>
    <home-more to="/culture" mode="dark" class="HomeStory-more" />
  </b-card>
</template>

<script>
import BaseVideo from "@/components/BaseVideo";
import HomeMore from "./HomeMore";

export default {
  name: "HomeStory",
  components: { BaseVideo, HomeMore },
  props: {
    content: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.HomeStory {
  &.card {
    background-color: #f8f7e5;
  }

  &-header {
    font-size: $h4-font-size;
  }

  &-more {
    top: -38px;
  }

  &-video {
    height: 240px;
  }

  @include media-breakpoint-up(lg) {
    &-header {
      font-size: $h3-font-size;
    }

    &-body {
      font-size: $font-size-lg;
      line-height: 2;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &-header {
      font-size: $h1-font-size;
    }

    &-more {
      top: -48px;
    }

    &-body {
      p {
        -webkit-line-clamp: 5;
      }
    }

    &-video {
      height: 400px;
    }
  }
}
</style>
