// http://triwiseadmin.ezmate.cn/apidoc?version=V1.0
import request from "@/utils/request";

// 获取页面内容
export function fatchCurrent(id) {
  return request({ url: "/api/Cms/info", data: { cid: id } });
}

// 获取分页内容
export function fatchCurrentPage(data) {
  return request({ url: "/api/Cms/info", data });
}

// 获取企业文化
export function fatchCulture() {
  return request({ url: "/api/Cms/culture" });
}
