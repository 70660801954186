export default [
  {
    path: "/core",
    name: "Core",
    component: () => import(/* webpackChunkName: "core" */ "@/views/Team/Core"),
  },
  {
    path: "/consultant",
    name: "Consultant",
    component: () =>
      import(/* webpackChunkName: "consultant" */ "@/views/Team/Consultant"),
  },
];
