<template>
  <b-container
    fluid
    class="HomeCase px-3 py-4 px-lg-5 py-lg-5 px-xl-7 py-xl-5 text-center"
  >
    <h2 class="mb-0">投资案例</h2>
    <template v-if="list.length > 0">
      <div class="position-relative my-5 mt-lg-6">
        <base-swiper
          ref="swiper"
          :list="list"
          :options="swiperOptions"
          class="HomeCase-swiper"
        >
          <template v-slot:default="slotProps">
            <div
              class="HomeCase-logo"
              :style="{ backgroundImage: `url('${slotProps.el.thumb}')` }"
            />
          </template>
        </base-swiper>
        <b-button
          variant="outline-secondary"
          class="HomeCase-arrow HomeCase-arrow-left position-absolute h-100 p-0 border-0 bg-transparent shadow-none"
          @click="onPrev"
        >
          <b-icon icon="chevron-left" />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="HomeCase-arrow HomeCase-arrow-right position-absolute h-100 p-0 border-0 bg-transparent shadow-none"
          @click="onNext"
        >
          <b-icon icon="chevron-right" />
        </b-button>
      </div>
      <b-button
        variant="outline-secondary"
        size="sm"
        to="/portfolio"
        class="HomeCase-more"
      >
        查看更多
      </b-button>
    </template>
  </b-container>
</template>

<script>
import BaseSwiper from "@/components/BaseSwiper";

export default {
  name: "HomeCase",
  components: { BaseSwiper },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 6,
        breakpoints: {
          // sm
          576: {
            slidesPerView: 1,
          },
          // md
          768: {
            slidesPerView: 2,
          },
          // lg
          992: {
            slidesPerView: 3,
          },
          // xl
          1200: {
            slidesPerView: 4,
          },
          // xxl
          1500: {
            slidesPerView: 5,
          },
        },
      },
    };
  },
  methods: {
    onNext() {
      this.$refs.swiper.slideNext();
    },
    onPrev() {
      this.$refs.swiper.slidePrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.HomeCase {
  background-color: $light;

  h2 {
    font-size: $h4-font-size;
  }

  &-swiper {
    margin: 0 40px;
  }

  &-arrow {
    top: 0;
    font-size: $h2-font-size;
    color: $gray-500;

    &:hover,
    &:not(:disabled):not(.disabled):active {
      color: $secondary;
    }

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &-logo {
    width: 180px;
    height: 63px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @include media-breakpoint-up(lg) {
    background-color: $white;

    h2 {
      font-size: $h3-font-size;
    }
  }

  @include media-breakpoint-up(xl) {
    h2 {
      font-size: $h1-font-size;
    }

    &-more {
      font-size: $font-size-base;
    }
  }
}
</style>
